import frameworkGetAll from "@/graphql/framework/get_all.gql";
import get_questions_by_section from "@/graphql/section/get_questions_by_section.gql";
import upsertAnswer from "@/graphql/answer/upsert_answer.gql";
import accountAnswers from "@/graphql/answer/account_answers.gql";
import gql_insert_compliance_section_target from "@/graphql/compliance_section_target/insert_compliance_section_target.gql";
import gql_get_snapshots from "@/graphql/answer_snapshot/get_snapshots.gql";
import answerProductDelete from "@/graphql/answer_product/delete.gql";
import gql_update_answer_snapshot from "@/graphql/answer_snapshot/update_answer_snapshot.gql";
import gql_get_section from "@/graphql/caf/caf_get_section.gql";
import { ElNotification } from "element-plus";
import { query } from "@/api";

function returnDeploymentValue(deployment) {
  if (deployment === "Yes") {
    return 100;
  } else if (deployment === "Some") {
    return 50;
  } else if (deployment === "N/A") {
    return 100;
  }
  return 0;
}

const defaultStoreState = () => ({
  currentActiveAnswerBox: null,
  accountSnapshotOverride: null,
  snapshotChangesLocked: true,
  section: null,
  emptyForm: {
    company_name: null,
    summary_title_text: null,
    summary_text_left: null,
    summary_text_right: null,
    summary_text_quote: null,
    assessor_name: null,
    assessor_job_title: null,
    account_id: null,
  },
  form: {
    company_name: null,
    summary_title_text: null,
    summary_text_left: null,
    summary_text_right: null,
    summary_text_quote: null,
    assessor_name: null,
    assessor_job_title: null,
    account_id: null,
  },
  accountAnswers: null,
  accountAnswerSnapshots: null,
  frameworks: null,
  selectedFramework: null,
  questionsBySection: null,
  unsavedAnswers: [],
  unsavedBaselineTargets: [],
});

const state = defaultStoreState();

const getters = {
  currentActiveAnswerBox: (state) => state.currentActiveAnswerBox,
  computeReactiveMaturity: (state, getters) => {
    const questionsBySection = getters.questionsBySection;
    const questionsImplementationGroupTotals =
      getters.questionsImplementationGroupTotals;
    if (!questionsImplementationGroupTotals) return null;
    if (!questionsBySection || !questionsBySection?.length) return null;
    let newObj = {
      reactive: {
        percent: 0,
        current: 0,
        max: questionsImplementationGroupTotals.reactive,
      },
      proactive: {
        percent: 0,
        current: 0,
        max: questionsImplementationGroupTotals.proactive,
      },
      enterprise: {
        percent: 0,
        current: 0,
        max: questionsImplementationGroupTotals.enterprise,
      },
      total: 0,
    };
    questionsBySection
      .filter((f) => f.question.framework_id === 5)
      .forEach((d) => {
        const deploymentValue = returnDeploymentValue(d.deployment_status_text);
        if (d.question.ig1) {
          newObj.reactive.current = newObj.reactive.current + deploymentValue;
          newObj.reactive.percent = Math.floor(
            (newObj.reactive.current / newObj.reactive.max) * 100
          );
        }

        if (d.question.ig2) {
          newObj.proactive.current = newObj.proactive.current + deploymentValue;
          newObj.proactive.percent = Math.floor(
            (newObj.proactive.current / newObj.proactive.max) * 100
          );
        }

        if (d.question.ig3) {
          newObj.enterprise.current =
            newObj.enterprise.current + deploymentValue;
          newObj.enterprise.percent = Math.floor(
            (newObj.enterprise.current / newObj.enterprise.max) * 100
          );
        }
        return (newObj.total = newObj.total + 100);
      });

    return newObj;
  },
  accountSnapshotOverride: (state) => state.accountSnapshotOverride,
  reportPrint: (state) => {
    return {
      ...state.form,
      summary_title_text: state.form.summary_title_text
        ? state.form.summary_title_text.replace(
            /{company_name}/g,
            state.form.company_name
          )
        : null,
      summary_text_left: state.form.summary_text_left
        ? state.form.summary_text_left.replace(
            /{company_name}/g,
            state.form.company_name
          )
        : null,
      summary_text_right: state.form.summary_text_right
        ? state.form.summary_text_right.replace(
            /{company_name}/g,
            state.form.company_name
          )
        : null,
      summary_text_quote: state.form.summary_text_quote
        ? state.form.summary_text_quote.replace(
            /{company_name}/g,
            state.form.company_name
          )
        : null,
    };
  },
  unsavedAnswers: (state) => state.unsavedAnswers,
  currentSnapshot: (state, getters) => {
    if (!getters.accountSnapshotOverride) return null;
    return getters.accountAnswerSnapshots.filter(
      (f) => f.id === getters.accountSnapshotOverride
    )?.length
      ? getters.accountAnswerSnapshots.filter(
          (f) => f.id === getters.accountSnapshotOverride
        )[0]
      : null;
  },
  currentSnapshotData: (state, getters) => {
    if (!getters.currentSnapshot) return null;
    return getters.currentSnapshot.data;
  },
  accountAnswerSnapshots: (state) => state.accountAnswerSnapshots,
  unsavedBaselineTargets: (state) => state.unsavedBaselineTargets,
  questionsBySection: (_state, getters, _rootState, rootGetters) => {
    const answersBySelectedSnapshot = getters.answersBySelectedSnapshot;
    const selectedFramework = rootGetters["efficiency/selectedFramework"];

    if (answersBySelectedSnapshot?.length) return answersBySelectedSnapshot;
    const answers = rootGetters["account/currentAccountDetail"]?.answers;
    const tenantFrameworkConfiguration =
      rootGetters["tenant/tenantFrameworkConfiguration"];
    if (!answers || !answers.length) return null;
    if (tenantFrameworkConfiguration?.length) {
      return answers.filter((f) => {
        const frameworkId = f.question?.framework_id || null;

        if (frameworkId !== selectedFramework) {
          return false;
        }
        const frameworkDisabled = tenantFrameworkConfiguration.filter(
          (f) => f.framework_id === frameworkId
        );
        if (frameworkDisabled?.length) {
          return !frameworkDisabled[0].framework_disabled;
        } else {
          return true;
        }
      });
    } else {
      const filteredAnswers = answers.filter(
        (answer) => answer.question?.framework_id === selectedFramework
      );
      return filteredAnswers;
    }
  },
  answersBySelectedSnapshot: (_state, getters) => {
    const currentSnapshotData = getters.currentSnapshotData;
    if (!currentSnapshotData || !currentSnapshotData.length) return null;
    let newArr = [];
    currentSnapshotData.forEach((s) => {
      s.questions.forEach((q) => {
        q.answers.forEach((a) => {
          newArr.push(a);
        });
      });
    });
    return newArr;
  },
  questionsByTechnology(_state, getters) {
    const questionsBySection = getters.questionsBySection;
    if (!questionsBySection || !questionsBySection.length) return null;
    let newObj = {};
    questionsBySection.forEach((s) => {
      if (
        !s.question ||
        !s.question.technologies ||
        !s.question.technologies.length
      )
        return;
      s.question.technologies.forEach((t) => {
        if (!newObj[t]) {
          newObj[t] = {
            num_no: s.deployment_status_text === "No" ? 1 : 0,
            num_some: s.deployment_status_text === "Some" ? 1 : 0,
            num_roadmap_projects:
              s.reason_for_no?.includes("roadmap") ||
              s.reason_for_some?.includes("roadmap")
                ? 1
                : 0,
            pct: null,
            possible_no: 1,
          };
        } else {
          if (s.reason_for_no?.includes("roadmap")) {
            newObj[t].num_roadmap_projects++;
          }
          s.deployment_status_text === "No" ? newObj[t].num_no++ : null;
          s.deployment_status_text === "Some" ? newObj[t].num_some++ : null;
          newObj[t].possible_no++;
        }
      });
    });
    Object.entries(newObj).forEach((e) => {
      newObj[e[0]].pct = Math.abs(
        ((newObj[e[0]].num_no + newObj[e[0]].num_some * 0.5) /
          newObj[e[0]].possible_no) *
          100
      ).toFixed(1);
    });
    return newObj;
  },
  knownAcceptedRisk(_state, getters) {
    const questionsBySection = getters.questionsBySection;
    if (!questionsBySection || !questionsBySection.length) return null;
    let newObj = {};
    let finalObj = {};
    questionsBySection.forEach((s) => {
      if (!s.question?.section?.name) return;

      if (!newObj[s.question.section.name]) {
        newObj[s.question.section.name] = {
          total: 1,
          known_accepted_risk: 0,
        };
      } else {
        newObj[s.question.section.name].total++;
      }
      if (
        s.reason_for_no &&
        s.reason_for_no === "Known and Accepted Risk" &&
        s.deployment_status_text === "No"
      ) {
        newObj[s.question.section.name].known_accepted_risk++;
      }
      if (
        s.reason_for_some &&
        s.reason_for_some === "Known and Accepted Risk" &&
        s.deployment_status_text === "Some"
      ) {
        newObj[s.question.section.name].known_accepted_risk += 0.5;
      }
    });
    Object.entries(newObj).forEach((d) => {
      const values = d[1];
      let totalRisk = values.total - values.known_accepted_risk;
      const pct = Math.round((totalRisk / values.total) * 100);
      if (!values.known_accepted_risk) {
        finalObj[d[0]] = 100;
      } else {
        finalObj[d[0]] = pct;
      }
    });
    return finalObj;
  },
  maturityLevels(_state, getters) {
    const questionsBySection = getters.questionsBySection;
    if (!questionsBySection || !questionsBySection?.length) return null;

    const maturityLevels = {
      Initial: 1,
      Repeatable: 2,
      Defined: 3,
      Managed: 4,
      Optimizing: 5,
    };

    const newArr = questionsBySection
      .map((a) => {
        if (a.deployment_status_text === "Yes") {
          const score = !isNaN(a.maturity_level)
            ? Number(a.maturity_level)
            : maturityLevels[a.maturity_level];
          return { ...a, maturity_score: score };
        } else if (a.deployment_status_text === "Some") {
          return { ...a, maturity_score: 0.5 };
        } else if (a.deployment_status_text === "No") {
          return { ...a, maturity_score: 0 };
        }
      })
      .filter((a) => a);

    return Math.abs(
      newArr.reduce((a, b) => a + b.maturity_score, 0) / newArr.length
    ).toFixed(2);
  },
  questionsByReasonsForNo(_state, getters) {
    const questionsBySection = getters.questionsBySection;
    if (!questionsBySection || !questionsBySection.length) return null;
    let newObj = {};
    questionsBySection.forEach((s) => {
      if (s.reason_for_no) {
        if (!newObj[s.reason_for_no]) {
          newObj[s.reason_for_no] = 1;
        } else {
          newObj[s.reason_for_no]++;
        }
      }
    });
    return newObj;
  },
  questionsImplementationGroupTotals(state, getters, _rootState, rootGetters) {
    const complianceSectionsQuestionsAnswersAll =
      getters.complianceSectionsQuestionsAnswersAll;
    if (
      !complianceSectionsQuestionsAnswersAll ||
      !complianceSectionsQuestionsAnswersAll.length
    )
      return null;
    const selectedFramework = rootGetters["efficiency/selectedFramework"];
    if (selectedFramework !== 5) return null;
    let newObj = {
      reactive: 0,
      proactive: 0,
      enterprise: 0,
    };
    let sectionObj = {};
    complianceSectionsQuestionsAnswersAll.forEach((a) => {
      sectionObj[a.name] = {};
      a.questions.forEach((q) => {
        if (q.ig1) {
          newObj.reactive = newObj.reactive + 100;
          if (!sectionObj[a.name].ig1_reactive) {
            sectionObj[a.name].ig1_reactive = 100;
          } else {
            sectionObj[a.name].ig1_reactive =
              sectionObj[a.name].ig1_reactive + 100;
          }
        }

        if (q.ig2) {
          newObj.proactive = newObj.proactive + 100;
          if (!sectionObj[a.name].ig2_proactive) {
            sectionObj[a.name].ig2_proactive = 100;
          } else {
            sectionObj[a.name].ig2_proactive =
              sectionObj[a.name].ig2_proactive + 100;
          }
        }

        if (q.ig3) {
          newObj.enterprise = newObj.enterprise + 100;
          if (!sectionObj[a.name].ig3_enterprise) {
            sectionObj[a.name].ig3_enterprise = 100;
          } else {
            sectionObj[a.name].ig3_enterprise =
              sectionObj[a.name].ig3_enterprise + 100;
          }
        }
      });
    });
    return {
      ...newObj,
      sectionObj,
    };
  },
  complianceSectionsQuestionsAnswersAll(
    state,
    getters,
    _rootState,
    rootGetters
  ) {
    const currentSnapshotData = rootGetters["efficiency/currentSnapshotData"];
    if (currentSnapshotData?.length) return currentSnapshotData;
    const userTenantDetail = rootGetters["tenant/userTenantDetail"];
    let selectedFramework = rootGetters["efficiency/selectedFramework"];
    const currentAccountDetail = rootGetters["account/currentAccountDetail"];
    if (!selectedFramework) {
      if (currentAccountDetail?.answers?.length) {
        selectedFramework =
          currentAccountDetail.answers[0].question?.framework_id || null;
      }
    }
    if (currentAccountDetail?.basic_subscription_framework) {
      selectedFramework = currentAccountDetail.basic_subscription_framework;
    }
    let frameworkConfiguration = userTenantDetail
      ? userTenantDetail.framework_configuration
      : null;

    const answers = getters.questionsBySection;
    const accountTechniquesAll = rootGetters["threat/accountTechniquesAll"];
    const threatIndexThresholdsForAudit =
      rootGetters["threat/threatIndexThresholdsForAudit"];
    if (!state.questionsBySection) return null;
    return state.questionsBySection
      .filter((s) => s.framework_id === selectedFramework)
      .map((d) => {
        if (frameworkConfiguration?.length) {
          const relevantFrameworkFilter = frameworkConfiguration.filter(
            (f) => f.framework_id === selectedFramework
          );
          if (relevantFrameworkFilter && relevantFrameworkFilter.length === 1) {
            const enabledQuestions =
              relevantFrameworkFilter[0].enabled_questions;
            if (enabledQuestions && enabledQuestions.length > 0) {
              d.questions = d.questions.filter(
                (f) => enabledQuestions.indexOf(f.id) > -1
              );
            }
          }
        }
        return {
          ...d,
          questions: d.questions.map((q) => {
            let highest = 0;
            let cleanTTPs = null;
            let threat_relevance = null;
            if (
              q.ttps?.length &&
              accountTechniquesAll &&
              accountTechniquesAll.length &&
              threatIndexThresholdsForAudit
            ) {
              cleanTTPs = [
                ...new Set(q.ttps.map((d) => d.technique_id.split(".")[0])),
              ];
              cleanTTPs = cleanTTPs.map((d) => {
                const foundIndex =
                  accountTechniquesAll.findIndex((f) => f.technique_id === d) ||
                  null;
                return {
                  technique_id: d,
                  index: foundIndex,
                };
              });
              if (cleanTTPs?.length) {
                cleanTTPs.forEach((d) => {
                  if (d.index && d.index > highest) highest = d.index;
                });
              }
              if (
                highest >= threatIndexThresholdsForAudit.medium &&
                highest < threatIndexThresholdsForAudit.high
              )
                threat_relevance = "Medium Threat Relevance";
              else if (highest >= threatIndexThresholdsForAudit.high)
                threat_relevance = "High Threat Relevance";
            }
            return {
              ...q,
              answers:
                answers && answers.length > 0
                  ? answers.filter((a) => a.question_id === q.id)
                  : q.answers,
              threat_relevance,
              mapping: q.mapping.filter(
                (m) => m.src_framework_id === selectedFramework
              ),
            };
          }),
        };
      });
  },
  frameworks(state, _getters, _rootState, rootGetters) {
    const frameworks = state.frameworks;
    const tenantFrameworkConfiguration =
      rootGetters["tenant/tenantFrameworkConfiguration"];
    if (!frameworks || !tenantFrameworkConfiguration) return frameworks;
    let newArr = [];
    frameworks.forEach((d) => {
      if (tenantFrameworkConfiguration?.length) {
        const fwConfig = tenantFrameworkConfiguration.filter(
          (f) => f.framework_id === d.id
        );
        if (fwConfig?.length) {
          if (fwConfig[0].framework_disabled === true) {
            return;
          } else {
            return newArr.push(d);
          }
        } else {
          return newArr.push(d);
        }
      } else {
        return newArr.push(d);
      }
    });
    return newArr;
  },
  frameworkQuestionTotals(state, _getters, _rootState, rootGetters) {
    try {
      const frameworks = state.frameworks;
      const tenantFrameworkConfiguration =
        rootGetters["tenant/tenantFrameworkConfiguration"];
      if (!frameworks?.length) return null;
      let newObj = {};
      frameworks.forEach((d) => {
        if (tenantFrameworkConfiguration?.length) {
          const fwConfig = tenantFrameworkConfiguration.filter(
            (f) => f.framework_id === d.id
          );
          if (fwConfig?.length) {
            if (fwConfig[0].framework_disabled === true) {
              return;
            } else {
              return (newObj[d.id] = d.questions_aggregate.aggregate.count);
            }
          } else {
            return (newObj[d.id] = d.questions_aggregate.aggregate.count);
          }
        } else {
          return (newObj[d.id] = d.questions_aggregate.aggregate.count);
        }
      });
      return newObj;
    } catch (error) {
      console.log(error);
    }
  },
  selectedFramework: (state, getters, rootState, rootGetters) => {
    const currentAccountDetail = rootGetters["account/currentAccountDetail"];
    const userRole = rootGetters["user/userRole"];
    const userFrameworkAccess = rootGetters["user/userFrameworkAccess"];
    if (userRole === "mssp_rw" && userFrameworkAccess?.length) {
      if (
        state.selectedFramework &&
        userFrameworkAccess.includes(state.selectedFramework)
      ) {
        return state.selectedFramework;
      }
      return userFrameworkAccess[0];
    }

    if (currentAccountDetail?.basic_subscription_framework) {
      return currentAccountDetail.basic_subscription_framework;
    }

    return state.selectedFramework;
  },
  selectedFrameworkDetail: (state, getters) => {
    if (!state.frameworks || !getters.selectedFramework) {
      return null;
    } else {
      return state.frameworks.filter(
        (f) => f.id === getters.selectedFramework
      )[0];
    }
  },
  section: (state, getters, rootState, rootGetters) => {
    return state.section;
  },
};

const actions = {
  async getSection({ commit }, payload) {
    try {
      const { data } = await query({
        query: gql_get_section,
      });
      commit("SET_SECTION", data.section);
    } catch (error) {
      console.error(error);
    }
  },
  setCurrentActiveAnswerBox({ commit }, payload) {
    commit("SET_CURRENT_ACTIVE_ANSWER_BOX", payload);
  },
  setSnapshotOverride({ commit }, payload) {
    commit("SET_ACCOUNT_SNAPSHOT_OVERRIDE", payload);
  },
  async getAccountAnswerSnapshots({ commit }, payload) {
    try {
      const { data } = await query({
        query: gql_get_snapshots,
        variables: {
          framework_id: payload.framework_id,
          account_id: payload.account_id,
        },
      });

      if (data && data.answer_snapshot) {
        commit("SET_ACCOUNT_ANSWER_SNAPSHOTS", data.answer_snapshot);
      } else {
        throw new Error("Failed to retrieve account answer snapshots.");
      }
    } catch (error) {
      console.error(error);
      // Handle error appropriately
    }
  },
  async commitBaselineTargets({ state, dispatch }) {
    try {
      const { data } = await query({
        query: gql_insert_compliance_section_target,
        variables: {
          objects: state.unsavedBaselineTargets,
        },
      });
      dispatch("clearUnsavedBaselineTargets");
    } catch (error) {
      console.error(error);
    }
  },
  async commitSnapshotAnswers({ state, getters, dispatch }) {
    let currentSnapshotData = getters.currentSnapshotData;
    if (!currentSnapshotData?.length) return;
    if (
      !state.unsavedAnswers ||
      state.unsavedAnswers.filter((f) => f.has_error).length > 0
    ) {
      ElNotification({
        title: "Oops!",
        message:
          "There are mandatory fields left, please ensure all are filled out before saving",
        type: "warning",
      });
      throw { error: true, message: "Validation issues" };
    }

    currentSnapshotData.forEach((d, i) => {
      d.questions.forEach((q, ii) => {
        q.answers.forEach((a, iii) => {
          const foundIndex = state.unsavedAnswers.findIndex(
            (x) => x.question_id === a.question_id
          );
          if (foundIndex > -1) {
            currentSnapshotData[i].questions[ii].answers[iii] =
              state.unsavedAnswers[foundIndex];
          }
        });
      });
    });

    try {
      const { data } = await query({
        query: gql_update_answer_snapshot,
        variables: {
          id: getters.accountSnapshotOverride,
          data: currentSnapshotData,
        },
      });

      if (data) {
        dispatch("clearUnsavedAnswer");
        return;
      }

      throw { error: data, type: "No data" };
    } catch (e) {
      throw { error: e, type: "Connection error" };
    }
  },
  async commitAnswers({ state, dispatch }) {
    if (
      !state.unsavedAnswers ||
      state.unsavedAnswers.filter((f) => f.has_error).length > 0
    ) {
      ElNotification({
        title: "Oops!",
        message:
          "There are mandatory fields left, please ensure all are filled out before saving",
        type: "warning",
      });
      throw console.log({ error: true, message: "Validation issues" });
    }

    if (state.unsavedAnswers?.length < 1) {
      return console.log({ error: true, message: "No unsaved answers" });
    }

    const ogUnsavedAnswers = JSON.stringify(state.unsavedAnswers);

    const unsavedAnswers = state.unsavedAnswers.map((d) => {
      const { products, ...newObj } = d;

      if (
        newObj.deployment_status_text === "No" ||
        newObj.deployment_status_text === "Some"
      ) {
        newObj.maturity_level = null;
      }
      if (
        newObj.deployment_status_text === "No" ||
        newObj.deployment_status_text === "Yes"
      ) {
        newObj.reason_for_some = null;
      }
      if (
        newObj.deployment_status_text === "Some" ||
        newObj.deployment_status_text === "Yes"
      ) {
        newObj.reason_for_no = null;
      }

      if (products?.length) {
        newObj.products = {
          on_conflict: {
            constraint: "answer_product_pkey",
            update_columns: [],
          },
          data: products.map((p) => {
            return {
              product_id: p.product_id,
            };
          }),
        };
      }

      Object.entries(newObj).forEach(([key, value]) => {
        if (typeof value === "object" && key !== "products") {
          delete newObj[key];
        }
      });

      delete newObj.section_id;
      delete newObj.has_error;

      return newObj;
    });

    try {
      const { data } = await query({
        query: upsertAnswer,
        variables: {
          objects: unsavedAnswers,
        },
      });
      if (data) {
        await dispatch(
          "account/optimisticLoadAnswers",
          JSON.parse(ogUnsavedAnswers),
          { root: true }
        );
        dispatch("clearUnsavedAnswer");
        return;
      }

      throw { error: data, type: "No data" };
    } catch (e) {
      throw { error: e, type: "Connection error" };
    }
  },
  async storeUnsavedAnswer({ state, commit, dispatch }, payload) {
    await commit("STORE_UNSAVED_ANSWER", payload);

    await dispatch("account/optimisticLoadAnswers", state.unsavedAnswers, {
      root: true,
    });
  },
  storeUnsavedBaselineTarget({ commit }, payload) {
    commit("STORE_UNSAVED_BASELINE_TARGET", payload);
  },
  clearUnsavedAnswer({ commit }) {
    commit("CLEAR_UNSAVED_ANSWER");
  },
  clearUnsavedBaselineTargets({ commit }) {
    commit("CLEAR_UNSAVED_BASELINE_TARGETS");
  },
  async getQuestionsBySection({ commit }, payload) {
    try {
      const { data } = await query({
        query: get_questions_by_section,
        variables: {
          account_id: payload,
        },
      });

      if (data) {
        const { section } = data;
        const transformedSections = section.map((d) => {
          return {
            ...d,
            questions: d.questions.map((q) => {
              return {
                ...q,
                answers:
                  q.answers && q.answers.length > 0
                    ? q.answers
                    : [
                        {
                          id: null,
                          answer: null,
                          note: null,
                          deployment_status_text: null,
                          reason_for_no: null,
                          maturity_level: null,
                          vendors: null,
                        },
                      ],
              };
            }),
          };
        });

        commit("SET_QUESTIONS_BY_SECTION", transformedSections);
      } else {
        throw { error: data, type: "No data" };
      }

      return;
    } catch (e) {
      throw { error: e, type: "Connection error" };
    }
  },
  updateCurrentFramework({ commit, dispatch, rootGetters }, content) {
    commit("SET_CURRENT_FRAMEWORK", content);

    dispatch(
      "efficiency/getAccountAnswerSnapshots",
      {
        framework_id: rootGetters["efficiency/selectedFramework"],
        account_id: rootGetters["user/activeAccount"],
      },
      { root: true }
    );
  },
  async changeCurrentFramework({ commit, dispatch, rootGetters }, content) {
    commit("SET_CURRENT_FRAMEWORK", content);

    // If your dispatches return promises:
    try {
      await Promise.all([
        dispatch(
          "user/updateFrameworkState",
          {
            framework_id: rootGetters["efficiency/selectedFramework"],
            account_id: rootGetters["user/activeAccount"],
          },
          { root: true }
        ),
        dispatch(
          "findings/getCurrentActuals",
          {
            account_id: rootGetters["user/activeAccount"],
            framework_id: rootGetters["efficiency/selectedFramework"],
          },
          { root: true }
        ),
        dispatch(
          "efficiency/getAccountAnswerSnapshots",
          {
            framework_id: rootGetters["efficiency/selectedFramework"],
            account_id: rootGetters["user/activeAccount"],
          },
          { root: true }
        ),
      ]);
    } catch (error) {
      console.error("Error in dispatches:", error);
      // Handle the error as needed
    }
  },
  async addAnswerProduct({ rootState, getters }, product) {
    try {
      const answerId = getters.currentActiveAnswerBox;
      rootState.account.currentAccountDetailAnswers.forEach((a) => {
        if (a.id === answerId) {
          // Check if the product already exists in products
          const existingProduct = a.products.find(
            (p) => p.product_id === product.id
          );
          if (!existingProduct) {
            // Only add the product if it doesn't exist
            a.products.push({
              answer_id: answerId,
              product_id: product.id,
              product: {
                id: product.id,
                logo: product.logo,
                logo_new: product.logo_new,
                logo_uploaded: product.logo_new || product.logo ? true : false,
                name: product.name || product.label,
              },
            });

            // Notify success
            ElNotification({
              title: "Success",
              message: "Product has been added",
              type: "success",
            });
          }
          // Otherwise, do not add or notify
        }
      });
    } catch (error) {
      ElNotification({
        title: "Error",
        message: error.message,
        type: "error",
      });
      throw error;
    }
  },
  async removeAnswerProduct({ rootState }, { answer_id, product_id }) {
    try {
      const { data } = await query({
        query: answerProductDelete,
        variables: {
          answer_id,
          product_id,
        },
      });

      if (!data) {
        throw new Error("No data");
      }

      rootState.account.currentAccountDetailAnswers.forEach((a) => {
        if (a.id === answer_id) {
          a.products = a.products.filter((p) => p.product_id !== product_id);
        }
      });

      ElNotification({
        title: "Success",
        message: "Product has been removed",
        type: "success",
      });
    } catch (error) {
      ElNotification({
        title: "Error",
        message: error.message,
        type: "error",
      });
      throw error;
    }
  },
  async getAllFrameworks({ commit }) {
    try {
      const { data } = await query({
        query: frameworkGetAll,
      });
      if (data) {
        const { framework } = data;
        commit("SET_FRAMEWORKS", framework);

        return framework;
      }
    } catch (e) {
      console.error(e);
    }
  },
};
// mutations
const mutations = {
  RESET_STORE(state) {
    Object.assign(state, defaultStoreState());
  },
  CLEAR_UNSAVED_ANSWER(state) {
    state.unsavedAnswers = [];
    window.localStorage.removeItem("unsaved_answers");
  },
  CLEAR_UNSAVED_BASELINE_TARGETS(state) {
    state.unsavedBaselineTargets = [];
  },
  STORE_UNSAVED_BASELINE_TARGET(state, content) {
    var foundIndex = state.unsavedBaselineTargets.findIndex(
      (x) =>
        x.account_id === content.account_id &&
        x.section_id === content.section_id
    );
    if (foundIndex > -1) {
      state.unsavedBaselineTargets[foundIndex] = content;
    } else {
      state.unsavedBaselineTargets.push(content);
    }
  },
  STORE_UNSAVED_ANSWER(state, content) {
    var foundIndex = state.unsavedAnswers.findIndex((x) => x.id == content.id);
    if (foundIndex > -1) {
      state.unsavedAnswers[foundIndex] = content;
    } else {
      state.unsavedAnswers.push(content);
    }
    window.localStorage.setItem(
      "unsaved_answers",
      JSON.stringify(state.unsavedAnswers)
    );
  },
  SET_QUESTIONS_BY_SECTION(state, content) {
    state.questionsBySection = content;
  },
  SET_FRAMEWORKS(state, content) {
    state.frameworks = content;
  },
  SET_CURRENT_FRAMEWORK(state, content) {
    state.selectedFramework = content;
  },
  SET_ACCOUNT_ANSWER_SNAPSHOTS(state, content) {
    state.accountAnswerSnapshots = content;
  },
  SET_ACCOUNT_SNAPSHOT_OVERRIDE(state, content) {
    state.accountSnapshotOverride = content;
  },
  SET_CURRENT_ACTIVE_ANSWER_BOX(state, content) {
    state.currentActiveAnswerBox = content;
  },
  SET_SECTION(state, content) {
    state.section = content;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
