import { createRouter, createWebHistory } from "vue-router";
import { watch } from "vue";

import store from "../store";
import axios from "axios";
import { AUTH_ENDPOINT } from "../config";

import Home from "@/views/Home.vue";
import CustomerHome from "@/views/Customer/CustomerHome.vue";
import NavLogout from "@/components/Nav/NavLogout.vue";
import Callback from "@/views/Auth/AuthCallback.vue";
import Auth from "@/views/Auth/Auth.vue";
import AzureIntegrationCallback from "@/views/Auth/AzureIntegrationCallback.vue";
import { jwtParser } from "@/composables/jwtParser";

const routes = [
  {
    path: "/",
    component: Home,
    children: [
      {
        path: "",
        name: "Home",
        component: CustomerHome,
      },
      {
        path: "account-list",
        name: "AccountList",
        meta: {
          title: "Account List",
          subtitle: "Details of all accounts you manage",
        },
        component: () =>
          import("@/components/Accounts/AccountsAssessmentTable.vue"),
      },
      {
        path: "/accounts",
        name: "AccountsHome",
        meta: {
          requiresSubscription: true,
          title: "Home",
          subtitle:
            "Overview of your managed service provision and associated account insights",
        },
        component: () => import("@/views/Accounts/AccountsHome.vue"),
        children: [
          {
            path: "",
            name: "AccountsDashboard",
            component: () => import("@/views/Accounts/AccountsDashboard.vue"),
          },
          {
            path: "assessments",
            name: "AccountsAssessmentTable",
            component: () =>
              import("@/components/Accounts/AccountsAssessmentTable.vue"),
          },
        ],
      },
      {
        path: "/improvement",
        name: "ImprovementHome",
        meta: {
          requiresSubscription: true,
          title: "Improvement",
          subtitle:
            "Identify potential improvement projects based on the current insight from your security posture. Alternatively, you can create your own projects and assign them to others in your organisation",
        },
        component: () => import("@/views/Improvement/ImprovementHome.vue"),
        children: [
          {
            path: "",
            name: "ImprovementProjectTable",
            component: () =>
              import("@/components/Improvement/ImprovementProjectTable.vue"),
          },
          {
            path: "create",
            name: "ImprovementProjectCreate",
            component: () =>
              import("@/components/Improvement/ImprovementProjectCreate.vue"),
            children: [
              {
                path: "",
                name: "ImprovementProjectCreateType",
                component: () =>
                  import(
                    "@/components/Improvement/ImprovementProjectCreateType.vue"
                  ),
              },
              {
                path: "framework",
                name: "ProjectFramework",
                component: () =>
                  import(
                    "@/components/Improvement/ImprovementProjectFramework.vue"
                  ),
              },
              {
                path: "status",
                name: "ImprovementProjectCreateStatus",
                component: () =>
                  import(
                    "@/components/Improvement/ImprovementProjectCreateStatus.vue"
                  ),
              },
              {
                path: "insights",
                name: "ImprovementProjectCreateInsights",
                component: () =>
                  import(
                    "@/components/Improvement/ImprovementProjectCreateInsights.vue"
                  ),
              },
              {
                path: "finalise",
                name: "ImprovementProjectCreateFinalise",
                component: () =>
                  import(
                    "@/components/Improvement/ImprovementProjectCreateFinalise.vue"
                  ),
              },
            ],
          },
          {
            path: ":id",
            name: "ProjectView",
            component: () =>
              import("@/components/Improvement/ImprovementProjectView.vue"),
          },
        ],
      },
      {
        path: "/insights",
        name: "InsightsHome",
        meta: {
          requiresSubscription: true,
          title: "Insights",
          subtitle: "Your customised cyber posture insights",
        },
        component: () => import("@/views/Insights/InsightsHome.vue"),
      },
      {
        path: "/compliance",
        meta: {
          requiresSubscription: true,
          title: "Compliance",
          subtitle:
            "Create and manage the most widely adopted compliance frameworks for your organisation",
        },
        component: () => import("@/views/Compliance/ComplianceHome.vue"),
        children: [
          {
            path: "",
            name: "ComplianceHome",
            component: () => import("@/views/Compliance/ComplianceAnswers.vue"),
          },
          {
            path: "findings",
            name: "ComplianceFindings",
            component: () =>
              import("@/views/Compliance/ComplianceFindings.vue"),
          },
          {
            path: "Dashboard",
            name: "ComplianceDashboard",
            component: () =>
              import("@/views/Compliance/ComplianceDashboards.vue"),
          },
          {
            path: "contributors",
            name: "ComplianceContributors",
            component: () =>
              import("@/views/Compliance/ComplianceContributors.vue"),
          },
          {
            path: "trends",
            name: "ComplianceTrends",
            component: () => import("@/views/Compliance/ComplianceTrends.vue"),
          },
          {
            path: "targets",
            name: "ComplianceBaselineCAF",
            component: () =>
              import("@/views/Compliance/ComplianceBaselineCAF.vue"),
          },
          {
            path: "heatmap",
            name: "ComplianceHeatmap",
            component: () => import("@/views/Compliance/ComplianceHeatmap.vue"),
          },
          {
            path: "report",
            name: "ComplianceReport",
            component: () => import("@/views/Compliance/ComplianceReport.vue"),
          },
        ],
      },
      {
        path: "/performance",
        name: "PerformanceHome",
        meta: {
          requiresSubscription: true,
          title: "Performance",
          subtitle:
            "View the dynamic output of your cybersecurity tooling landscape",
        },
        component: () => import("@/views/Performance/PerformanceHome.vue"),
        children: [
          {
            path: "",
            name: "PerformanceDashboard",
            component: () =>
              import("@/views/Performance/PerformanceDashboard.vue"),
          },
          {
            path: "metrics",
            name: "MetricsHome",
            meta: {
              title: "Metrics",
              subtitle: "Build Metric Widgets for Performance",
            },
            component: () => import("@/views/Metrics/MetricsHome.vue"),
            children: [
              {
                path: "",
                name: "MetricsDashboard",
                component: () => import("@/views/Metrics/MetricsDashboard.vue"),
              },
            ],
          },
          {
            path: "kpi",
            name: "PerformanceKPI",
            meta: {
              title: "Key Performance Indicator",
              subtitle: "Management of cyber performance related KPIs",
            },
            component: () => import("@/views/Performance/PerformanceKPI.vue"),
          },
          {
            path: "heatmap",
            name: "PerformanceHeatmap",
            component: () =>
              import("@/views/Performance/PerformanceHeatmap.vue"),
          },
          {
            path: "itam",
            name: "PerformanceITAM",
            component: () => import("@/views/Performance/PerformanceITAM.vue"),
          },
          {
            path: "identity",
            name: "PerformanceIdentity",
            component: () =>
              import("@/views/Performance/PerformanceIdentity.vue"),
          },
          {
            path: "control-risks",
            name: "PerformanceControlRisks",
            component: () =>
              import("@/views/Performance/PerformanceControlRisks.vue"),
            children: [
              {
                path: "",
                name: "ReportingThreatScorecard",
                component: () =>
                  import(
                    "@/components/ControlRisks/ReportingThreatScorecard.vue"
                  ),
              },
              {
                path: "sector-threat",
                name: "ReportingAvgSectorThreat",
                component: () =>
                  import(
                    "@/components/ControlRisks/ReportingAvgSectorThreat.vue"
                  ),
              },
              {
                path: "threat-hygiene",
                name: "ReportingThreatHygiene",
                component: () =>
                  import(
                    "@/components/ControlRisks/ReportingThreatHygiene.vue"
                  ),
              },
            ],
          },
          {
            path: "integrations",
            name: "PerformanceIntegrations",
            component: () =>
              import("@/views/Performance/PerformanceIntegrations.vue"),
          },
          {
            path: "technology",
            name: "PerformanceTechnology",
            component: () =>
              import("@/views/Performance/PerformanceTechnology.vue"),
          },
        ],
      },
      {
        path: "/analytics",
        name: "AnalyticsHome",
        meta: {
          requiresSubscription: true,
          title: "Analytics",
          subtitle:
            "Analyse your KPIs and KRI data to identify trends and patterns",
        },
        component: () => import("@/views/Analytics/AnalyticsHome.vue"),
        children: [
          {
            path: "",
            name: "AnalyticsDashboard",
            component: () => import("@/views/Analytics/AnalyticsDashboard.vue"),
          },
        ],
      },
      {
        path: "/threat",
        name: "ThreatHome",
        meta: {
          requiresSubscription: true,
          title: "Threat",
          subtitle:
            "Monitor your current personalised threat score taking into account the unique profile of your organisation",
        },
        component: () => import("@/views/Threat/ThreatHome.vue"),
      },
      {
        path: "/risk",
        name: "Risk",
        meta: {
          title: "Risk",
        },
        children: [
          {
            path: "",
            name: "RiskHome",
            meta: {
              title: "Risk Measures",
            },
            component: () => import("@/views/Risk/RiskHome.vue"),
          },
          {
            path: "simulator",
            name: "RiskSimulatorHome",
            meta: {
              title: "Risk Simulator",
            },
            component: () => import("@/views/Risk/RiskSimulatorHome.vue"),
          },
        ],
      },
      {
        path: "/products",
        name: "ProductHome",
        meta: {
          title: "Software Vendors",
          subtitle:
            "List of active software vendors, products, and capabilities",
        },
        component: () => import("@/views/Product/ProductHome.vue"),
      },
      {
        path: "/settings",
        name: "SettingsHome",
        meta: {
          requiresSubscription: true,
          title: "Settings",
          subtitle: "Manage your account settings and preferences",
        },
        component: () => import("@/views/Settings/SettingsHome.vue"),
        children: [
          {
            path: "",
            name: "SettingsProfile",
            component: () => import("@/views/Settings/SettingsProfile.vue"),
          },
          {
            path: "organisation",
            name: "SettingsOrganisation",
            meta: {
              requiresSubscription: true,
              title: "Organization Settings",
              subtitle: "Manage your organization's settings and configuration",
            },
            component: () =>
              import("@/views/Settings/SettingsOrganisation.vue"),
          },
        ],
      },
      {
        path: "/configuration",
        name: "ConfigurationHome",
        meta: {
          requiresSubscription: true,
          title: "System Configuration",
          subtitle:
            "Here you can adjust the configuration of the system and invite new users",
        },
        component: () => import("@/views/Configuration/ConfigurationHome.vue"),
        children: [
          {
            path: "",
            name: "ConfigurationUsers",
            component: () =>
              import("@/views/Configuration/ConfigurationUsers.vue"),
          },
          {
            path: "tenants",
            name: "ConfigurationTenants",
            component: () =>
              import("@/views/Configuration/ConfigurationTenants.vue"),
          },
          {
            path: "accounts",
            name: "ConfigurationAccounts",
            component: () =>
              import("@/views/Configuration/ConfigurationAccounts.vue"),
          },
          {
            path: "frameworks",
            name: "ConfigurationFrameworks",
            component: () =>
              import("@/views/Configuration/ConfigurationFrameworks.vue"),
          },
          {
            path: "netrunner",
            name: "ConfigurationNetrunner",
            component: () =>
              import("@/views/Configuration/ConfigurationNetrunner.vue"),
          },
          {
            path: "metrics",
            name: "ConfigurationMetrics",
            component: () =>
              import("@/views/Configuration/ConfigurationMetrics.vue"),
          },
          {
            path: "flowprogress",
            name: "ConfigurationFlowProgress",
            component: () =>
              import("@/views/Configuration/ConfigurationFlowProgress.vue"),
          },
        ],
      },
      {
        path: "/administration",
        meta: {
          requiresSubscription: true,
          title: "Administration",
          subtitle: "Manage orders, subscriptions, and schedules",
        },
        name: "AdministrationHome",
        component: () =>
          import("@/views/Administration/AdministrationHome.vue"),
        children: [
          {
            path: "",
            name: "AdministrationOrders",
            component: () =>
              import("@/views/Administration/AdministrationOrders.vue"),
          },
          {
            path: "subscriptions",
            name: "AdministrationSubscriptions",
            component: () =>
              import("@/views/Administration/AdministrationSubscriptions.vue"),
          },
          {
            path: "schedules",
            name: "AdministrationSchedules",
            component: () =>
              import("@/views/Administration/AdministrationSchedules.vue"),
          },
        ],
      },
      {
        path: "/settings",
        name: "UserSettings",
        meta: {
          title: "Your Settings",
          subtitle:
            "Information relating to your account, password, and profile",
        },
        component: () => import("@/views/User/UserSettings.vue"),
      },
      {
        path: "/cookie-policy",
        name: "PrivacyPolicy",
        meta: {
          title: "Cookie Policy",
          subtitle:
            "We use cookies to distinguish you from other users of our platform. This helps us to provide you with the best experience whilst allowing us to continiously improve our service.",
        },
        component: () => import("@/views/User/UserCookiePolicy.vue"),
      },
      {
        path: "/404",
        name: "NotFound",
        component: () => import("@/views/NotFound.vue"),
        meta: {
          title: "404",
          subtitle:
            "We use cookies to distinguish you from other users of our platform. This helps us to provide you with the best experience whilst allowing us to continiously improve our service.",
        },
      },
      {
        path: "/:catchAll(.*)",
        redirect: "/404",
      },
    ],
  },
  {
    path: "/login",
    redirect: "/auth/login",
  },
  {
    path: "/auth",
    component: Auth,
    children: [
      {
        path: "",
        redirect: "/auth/login",
      },
      {
        path: "login",
        name: "Login",
        component: () => import("@/views/Auth/AuthLogin.vue"),
      },
      {
        path: "register",
        name: "Register",
        component: () => import("@/views/Auth/AuthRegister.vue"),
      },
      {
        path: "register_invite",
        name: "RegisterInvite",
        component: () => import("@/views/Auth/AuthRegisterInvite.vue"),
      },
      {
        path: "forgot-password",
        name: "ForgotPassword",
        component: () => import("@/views/Auth/AuthForgotPassword.vue"),
      },
      {
        path: "reset-password",
        name: "ResetPassword",
        component: () => import("@/views/Auth/AuthResetPassword.vue"),
      },
      {
        path: "account_pending",
        name: "AuthAccountPending",
        component: () => import("@/views/Auth/AuthAccountPending.vue"),
      },
      {
        path: "validate",
        name: "Validate",
        component: () => import("@/views/Auth/AuthValidate.vue"),
      },
      {
        path: "loadingauth",
        name: "AuthLoading",
        component: () => import("@/views/Auth/AuthLoading.vue"),
      },
      {
        path: "azure_connect",
        name: "AzureIntegrationCallback",
        component: AzureIntegrationCallback,
        meta: {
          public: true,
        },
      },
      {
        path: "logout",
        name: "Logout",
        component: NavLogout,
      },
    ],
    meta: {
      public: true,
    },
  },
  {
    path: "/callback",
    name: "Callback",
    component: Callback,
    meta: {
      public: true,
    },
  },
  {
    path: "/onboard",
    name: "OnboardHome",
    component: () => import("@/views/Onboard/OnboardHome.vue"),
    meta: {
      hideMenu: true,
    },
  },
];
function updateDocumentTitle(route) {
  const brandedTitle = store?.getters?.["ui/VUE_APP_TITLE"] ?? "Arco Cyber";
  window.document.title = route.meta?.title
    ? `${route.meta.title} | ${brandedTitle}`
    : brandedTitle;
}

watch(
  () => store.getters["ui/VUE_APP_TITLE"],
  (updatedTitle) => {
    const currentRoute = router.currentRoute.value;
    if (currentRoute) {
      updateDocumentTitle(currentRoute);
    }
  }
);

const router = createRouter({
  mode: "history",
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta?.public) {
    next();
    return;
  }

  const parsedJwt = jwtParser();
  if (!parsedJwt) {
    next({
      path: "/auth",
      query: { redirect: to.fullPath },
    });
    return;
  }

  const userRole = parsedJwt?.["x-hasura-default-role"] || null;
  const tokenExp = localStorage.getItem("jwt_expires");
  const routeRequiresSubscription = (route) => {
    if (route.meta?.requiresSubscription) return true;
    if (route.matched && route.matched.length > 0) {
      return route.matched.some((r) => r.meta?.requiresSubscription);
    }

    return false;
  };

  const findSafeFallbackRoute = () => {
    const subscription = store.getters["tenant/subscription"];
    const isMssp = store.getters["tenant/isMssp"];

    if (
      !subscription ||
      !subscription.modules ||
      !subscription.modules.length
    ) {
      return { path: "/auth/login" };
    }

    if (isMssp) {
      const msspModules = ["mssp_module", "accounts"];
      for (const moduleName of msspModules) {
        const module = subscription.modules.find(
          (m) => !m.locked && (m.name === moduleName || m.route === moduleName)
        );

        if (module) {
          return { path: `/${module.route || module.name}` };
        }
      }
    }

    const accessibleModules = subscription.modules
      .filter((module) => !module.locked)
      .sort((a, b) => (a.order || 999) - (b.order || 999));

    if (!accessibleModules.length) {
      return { path: "/auth/login" };
    }

    for (const module of accessibleModules) {
      if (module.features && module.features.length > 0) {
        const unlockFeatures = module.features
          .filter((f) => !f.locked)
          .sort((a, b) => (a.order || 999) - (b.order || 999));

        if (unlockFeatures.length > 0) {
          const firstFeature = unlockFeatures[0];
          return {
            name: firstFeature.name,
            path: firstFeature.path
              ? `/${module.route}/${firstFeature.path}`
              : `/${module.route}`,
          };
        }
      }
    }

    const firstModule = accessibleModules[0];
    if (firstModule.route) {
      return { path: `/${firstModule.route}` };
    }

    return { path: "/auth/login" };
  };

  if (userRole === "pending_approval" && !to.meta?.public) {
    next({
      name: "AuthAccountPending",
    });
    return;
  }

  const homeComponents = store.getters["user/homeComponents"];
  if (!homeComponents.mssp || !homeComponents.account) {
    store.dispatch("user/setHomeComponents", {
      account: parsedJwt["x-hasura-account-home"],
      mssp: parsedJwt["x-hasura-mssp-home"],
    });
  }
  if (routeRequiresSubscription(to)) {
    const tenantLoaded = store.getters["tenant/tenantLoaded"];
    const subscription = store.getters["tenant/subscription"];

    if (!tenantLoaded || !subscription) {
      if (!store.getters["user/user"]?.id) {
        try {
          await store.dispatch("user/getCurrentUser");
        } catch (error) {
          console.error("Error loading user data:", error);
          next({ name: "NotFound" });
          return;
        }
      }

      const updatedSubscription = store.getters["tenant/subscription"];
      if (!updatedSubscription) {
        next({ name: "NotFound" });
        return;
      }
    }

    const modules = store.getters["tenant/subscription"]?.modules || [];
    const pathSegments = to.path.split("/").filter(Boolean);
    const firstSegment = pathSegments[0]?.toLowerCase();
    const moduleHasAccess = modules.some((module) => {
      const moduleName = module.name?.toLowerCase();
      const moduleRoute = (module.route || "")?.toLowerCase();
      return firstSegment === moduleName || firstSegment === moduleRoute;
    });

    if (moduleHasAccess) {
      const routeName = to.name;
      const accessedModule = modules.find((module) => {
        const moduleName = module.name?.toLowerCase();
        const moduleRoute = (module.route || "")?.toLowerCase();

        return firstSegment === moduleName || firstSegment === moduleRoute;
      });

      if (
        accessedModule &&
        accessedModule.features &&
        accessedModule.features.length > 0
      ) {
        const lockedFeature = accessedModule.features.find(
          (feature) => feature.name === routeName && feature.locked
        );

        if (lockedFeature) {
          next();
          return;
        }

        const hasFeatureAccess = accessedModule.features.some((feature) => {
          return feature.name === routeName && !feature.locked;
        });

        if (!hasFeatureAccess) {
          const fallbackRoute = findSafeFallbackRoute();
          next(fallbackRoute);
          return;
        }
      }
    } else {
      const complianceModule = modules.find(
        (module) => !module.locked && module.name.toLowerCase() === "compliance"
      );

      if (complianceModule) {
        next({ name: "ComplianceHome" });
        return;
      }

      const fallbackRoute = findSafeFallbackRoute();
      next(fallbackRoute);
      return;
    }
  }

  if (tokenExp) {
    const now = new Date().getTime();
    const exp = new Date(tokenExp).getTime() - 1000 * 60 * 2400;

    if (exp - now < 0) {
      try {
        const refreshToken = await axios.post(
          `${AUTH_ENDPOINT}/refresh_token`,
          {},
          {
            withCredentials: true,
          }
        );
        if (refreshToken?.data?.jwt_token) {
          localStorage.setItem("jwt_token", refreshToken.data.jwt_token);
          localStorage.setItem(
            "jwt_expires",
            refreshToken.data.jwt_token_expiry
          );
        }
      } catch (error) {
        localStorage.removeItem("jwt_expires");
        localStorage.removeItem("jwt_token");
        next({
          path: "/auth",
          query: { redirect: to.fullPath },
        });
        return;
      }
    }
  }
  next();
});
router.afterEach((to) => {
  updateDocumentTitle(to);
});
if (router.currentRoute.value) {
  updateDocumentTitle(router.currentRoute.value);
}
export default router;
