<template>
  <div @click="toggleSidebar" class="border-r border-gray-700 bg-arco-dark-card">
    <div class="flex h-full flex-col place-content-between">
      <div class="p-4 pt-2.5">
        <div class="flex w-full items-center justify-center">
          <div style="min-height: 60px" class="mt-4 flex flex-col" id="closeda">
            <img :src="VUE_APP_LOGO" :style="logoStyle" />
          </div>
        </div>

        <div @click.stop class="w-full" v-if="currentModules && currentModules.length > 0">
          <div v-for="item in currentModules" @click="changeRoute(item.name)" :key="item.route" class="mb-2">
            <div
              class="flex w-full cursor-pointer items-center justify-between rounded-md px-3 py-2 text-gray-200 hover:bg-blue-500"
              :class="{
                'bg-blue-500': route.path === '/' + item.name || route.path.startsWith('/' + item.name + '/'),
              }"
              :tooltip="menuWidth === '80px' ? item.display_name : null"
              flow="right"
            >
              <div class="flex items-center" v-if="item.icon">
                <span class="flex">
                  <el-icon :class="item.icon" size="20" />
                </span>
              </div>
              <div v-if="menuWidth !== '80px'" class="ml-5 w-full grow overflow-hidden text-sm">
                {{ item.display_name }}
              </div>
            </div>
          </div>
        </div>

        <div @click.stop class="w-full" v-if="lockedModules && lockedModules.length > 0">
          <div v-for="item in lockedModules" @click="changeRoute(item.name)" :key="item.route" class="mb-2">
            <div
              class="flex w-full cursor-pointer items-center justify-between rounded-md px-3 py-2 text-gray-200 hover:bg-blue-500"
              :class="{
                'bg-blue-500': route.path === '/' + item.name || route.path.startsWith('/' + item.name + '/'),
              }"
            >
              <div class="flex items-center" v-if="item.icon">
                <span class="flex" :class="route.path === '/' + item.name || route.path.startsWith('/' + item.name + '/') ? '' : 'text-gray-300/50'" :tooltip="menuWidth === '80px' ? item.display_name : null" flow="right">
                  <el-icon :class="item.icon" size="20" />
                </span>
              </div>
              <div v-if="menuWidth !== '80px'" class="ml-5 flex w-full grow overflow-hidden text-sm" :class="route.path === '/' + item.name || route.path.startsWith('/' + item.name + '/') ? '' : 'text-gray-300/60'">
                {{ item.display_name }} <el-icon class="ai ai-Lock ml-2.5 mt-0.5" size="15" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div @click.stop.prevent class="m-2 rounded-md border border-gray-700 bg-arco-dark-card-2 p-2">
        <div @click.stop.prevent class="pt-2.5">
          <div class="flex w-full cursor-pointer items-center justify-between rounded-md px-3 py-2 text-gray-200">
            <span class="flex" :tooltip="menuWidth === '80px' ? 'Notifications' : null" flow="right">
              <el-popover :width="450" :offset="menuWidth === '80px' ? 40 : 110" placement="right" trigger="click">
                <NavNotifications />
                <template #reference>
                  <div class="flex w-full justify-center">
                    <el-icon size="20" class="ai ai-Notification-1 cursor-pointer"></el-icon>
                    <div v-if="menuWidth !== '80px'" class="ml-5 w-full overflow-hidden whitespace-nowrap text-sm">Notifications</div>
                  </div>
                </template>
              </el-popover>
            </span>
          </div>
        </div>
        <div class="">
          <div @click="openKb(kburl)" class="flex w-full cursor-pointer items-center justify-between rounded-md px-3 py-2 text-gray-200">
            <span class="flex" :tooltip="menuWidth === '80px' ? 'Support Portal' : null" flow="right">
              <div class="flex w-full justify-center">
                <el-icon size="20" class="ai ai-Book-Help cursor-pointer"></el-icon>
              </div>
            </span>
            <div v-if="menuWidth !== '80px'" class="ml-5 w-full overflow-hidden whitespace-nowrap text-sm">Support Portal</div>
          </div>
        </div>
        <div class="">
          <div @click="router.push({ name: 'AuthLoading', query: { loginType: 'logout' } })" class="flex w-full cursor-pointer items-center justify-between rounded-md px-3 py-2 text-gray-200">
            <span class="flex" :tooltip="menuWidth === '80px' ? 'Log out' : null" flow="right">
              <div class="flex w-full justify-center">
                <el-icon size="20" class="ai ai-Logout-01 cursor-pointer"></el-icon>
              </div>
            </span>
            <div v-if="menuWidth !== '80px'" class="ml-5 w-full overflow-hidden whitespace-nowrap text-sm">Log out</div>
          </div>
        </div>
        <div @click.stop.prevent class="relative">
          <div class="px=1 mt-4 flex items-center justify-between border-t border-gray-700 py-3">
            <div class="flex items-center space-x-4">
              <div class="shrink-0">
                <div class="relative">
                  <img class="h-10 w-10 rounded-full ring-2 ring-arco-dark-card-2 ring-offset-2" v-if="user.avatar" :src="`${user.avatar}?t=${new Date().getTime()}`" alt="Profile Picture" />
                  <el-icon v-else class="ai ai-User-Circle-1 dark:text-gray-400" size="34" />

                  <div v-if="(features?.indexOf('mssp_account_switcher') > -1 || features?.indexOf('mssp_account_switcher_volaris') > -1) && !readOnly" @click.stop.prevent="openAccountSwitcher()" class="absolute -bottom-1 -right-1">
                    <span class="relative flex" :class="{ tooltip: menuWidth === '80px' }">
                      <img class="h-5 w-5 rounded-full bg-arco-dark-card ring-2 ring-arco-dark-card-2 ring-offset-2" v-if="computedLogo" :src="computedLogo" />
                      <div class="flex rounded-full bg-arco-dark-card" v-else><el-icon :size="20" class="ai ai-Help" /></div>
                      <div class="tooltip-content invisible absolute" v-if="menuWidth === '80px'">
                        <div class="flex items-center">
                          <div class="mr-2 flex rounded-md bg-green-500 p-2"><el-icon :size="20" class="ai ai-Information-Polygon flex" /></div>

                          <div class="flex flex-col">
                            <span class="text-sm font-bold">Current Active Account</span>
                            <div class="text-xs">{{ currentAccountDetail?.name }}</div>
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>

                <el-drawer v-model="drawerState" title="Select Account" :append-to-body="true" :with-header="false" size="40%">
                  <NavAccountTree id="hehe" />
                </el-drawer>
              </div>
              <div
                :class="{ '-translate-x-10 opacity-0': menuWidth === '80px', 'translate-x-0 opacity-100': menuWidth !== '80px' }"
                :style="{ transition: 'transform 300ms ease-out, opacity 300ms ease-out' }"
                class="flex flex-col justify-center"
              >
                <div v-if="!menu" class="text-md h-full whitespace-nowrap font-semibold">{{ user.first_name }} {{ user.last_name }}</div>
                <div v-if="!menu" class="whitespace-wrap flex items-center font-title text-xs">{{ currentAccountDetail?.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog align-center width="40%" v-model="dialogVisible">
      <div class="my-6 flex flex-col items-center">
        <img src="@/assets/arco_logo_only.svg" class="h-16" />
        <span class="my-4 font-title text-lg font-bold text-white">Sorry, this module is unavailable in the Sandbox version of arco</span>
        <span class="flex flex-row items-center rounded-md border border-gray-700 bg-arco-dark-card px-4 py-2 text-arco-blue hover:bg-arco-dark-card-2"
          ><a href="https://arcocyber.com/get-in-touch" target="_blank">Speak with Sales</a><i class="ai ai-Mail pl-2"
        /></span>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { ElDialog, ElIcon } from "element-plus";
import NavAccountTree from "@/components/Nav/NavAccountTree.vue";
const store = useStore();
const route = useRoute();
const router = useRouter();
const kburl = computed(() => store.getters["ui/kb_url"]);
const dialogVisible = ref(false);
const menu = ref(true);
const emits = defineEmits(["update:sidebar-open"]);
const drawerState = computed({
  get() {
    return store.state.ui.isDrawerOpen;
  },
  set(value) {
    store.dispatch(value ? "ui/openDrawer" : "ui/closeDrawer");
  },
});
const props = defineProps({
  menuWidth: String,
});
const userId = computed(() => store.getters["user/userId"]);
const menuWidth = ref(props.menuWidth);
const subscription = computed(() => store.getters["tenant/subscription"]);
const user = computed(() => store.getters["user/user"]);
const readOnly = computed(() => store.getters["user/userRole"] === "mssp_ro");
const VUE_APP_LOGO = computed(() => store.getters["ui/VUE_APP_LOGO"]);
const currentAccountDetail = computed(() => store.getters["account/currentAccountDetail"]);
const features = computed(() => store.getters["tenant/features"]);
const logoStyle = computed(() => {
  return menu.value ? "max-height: 80px; width: 50px;" : "max-height: 80px; width: 50px;";
});
const locked = ["products", "improvement", "administration", "configuration", "accounts"];
const computedLogo = computed(() => {
  const detail = currentAccountDetail.value;
  return detail?.logo ? detail.logo : detail?.company[0]?.has_logo ? `https://cdn-uk1.redsmart.app/company-data-files/logos/${currentAccountDetail.value?.company_id}.png` : null;
});

const lockedModules = computed(() => {
  return subscription.value?.modules?.filter((item) => !locked.includes(item.name) && item.locked === true);
});

const currentModules = computed(() => {
  return subscription.value?.modules?.filter((item) => item.locked === false);
});

const openKb = function (url) {
  if (url) {
    window.open(url, "_blank").focus();
  }
};
const toggleSidebar = () => {
  menu.value = !menu.value;
  menuWidth.value = menuWidth.value === "80px" ? "280px" : "80px";
  emits("update:sidebar-open", menuWidth.value === "280px");
};

function closeDrawer() {
  store.dispatch("ui/closeDrawer");
}

async function lockedRoutes(data) {
  let moduleRoutes = data.map((module) => module.name);
  await store.dispatch("tenant/getLockedPaths", moduleRoutes);
}

function toggleDrawer() {
  store.dispatch("ui/toggleDrawer");
}
const changeRoute = (routeObj, child = null) => {
  if (typeof routeObj === "string") {
    if (router.hasRoute(routeObj)) {
      router.push({ name: routeObj });
      return;
    }

    if (child) {
      router.push({ path: "/" + routeObj + "/" + child });
    } else {
      router.push({ path: "/" + routeObj });
    }
  } else {
    router.push(routeObj);
  }
};
function openAccountSwitcher() {
  store.dispatch("ui/openDrawer");
}
onMounted(async () => {
  if (!userId.value) {
    store.dispatch("user/getCurrentUser").then(() => {
      store.dispatch("account/getRecentAccounts");
    });
  } else {
    store.dispatch("account/getRecentAccounts");
  }
});
</script>

<style scoped lang="scss">
.slide-in-enter-active {
  transition: all 0.6s ease-out;
}

.slide-in-leave-active {
  transition: all 0s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-in-enter-from,
.slide-in-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
.layout-toggler {
  transition: all 0.3s;
  transform: rotate(180deg);
}
[tooltip] {
  position: relative;
  z-index: 1000;
}

.tooltip-content {
  z-index: 1001;
  text-align: left;
  min-width: 3em;
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: 5px;
  box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
  background: #000000;
  color: #fff;
  bottom: 0%;
  left: calc(100% + 5px);
  transform: translate(0.5em, -10%);
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 300ms, opacity 300ms;
}

.tooltip:hover .tooltip-content {
  visibility: visible;
  opacity: 0.9;
}
[tooltip]::before,
[tooltip]::after {
  text-transform: none;
  font-size: 0.9em;
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;

  opacity: 0;
}
[tooltip]::before {
  content: "";
  z-index: 1001;
}
[tooltip]::after {
  content: attr(tooltip);
  text-align: center;
  min-width: 3em;
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 15px;
  text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: 5px;
  box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
  background: #000000;
  color: #fff;
  z-index: 1000;
}
[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

[tooltip=""]::before,
[tooltip=""]::after {
  display: none !important;
}
[tooltip][flow^="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: #333;
  right: calc(0em - 5px);
  transform: translate(0.5em, -50%);
}
[tooltip][flow^="right"]::after {
  top: 50%;
  left: calc(100% + 5px);
  transform: translate(0.5em, -50%);
}
@keyframes tooltip {
  to {
    opacity: 0.9;
    transform: translate(0, -50%);
  }
}
[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after {
  animation: tooltip 300ms ease-out forwards;
}
.tooltip:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
  animation: tooltip-show 300ms ease-out forwards;
}

@keyframes tooltip-show {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
.text {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.menu-expanded .text {
  opacity: 1;
}
</style>
