import update_user_by_pk from "@/graphql/user/update_user_by_pk.gql";
import getUser from "@/graphql/user/get_user.gql";
import getComplianceDashboards from "@/graphql/user/get_compliance_dashboards.gql";
import getDefaultComplianceDashboards from "@/graphql/user/get_default_compliance_dashboards.gql";
import gql_update_user_framework_state from "@/graphql/user/update_user_framework_state.gql";
import gqlGetArcoIntegrations from "@/graphql/tool/get_integrations.gql";
import getTenant from "@/graphql/tenant/get_tenant.gql";
import { query } from "@/api";
import axios from "axios";
import { ElNotification } from "element-plus";
import getUserFrameworkAccess from "@/graphql/queries/get_user_framework_access.gql";

const defaultStoreState = () => ({
  jwt: null,
  isImpersonating: localStorage.getItem("imp_orig_jwt_token") ? true : false,
  user: {},
  arcoIntegrations: null,
  homeComponents: {
    account: null,
    mssp: null,
  },
  msspHome: null,
  complianceDashboards: [],
  userFrameworkAccess: [],
});

const state = defaultStoreState();

const getters = {
  isImpersonating: (state) => state.isImpersonating,
  jwt: (state) => state.jwt,
  userId: (state) => state.user?.id || null,
  loading: (state) => state.loading,
  userFrameworkState: (state) => state.user?.state_account_framework || null,
  onboarding: (state) => state.user?.onboarded || null,
  homeComponents: (state) => state.homeComponents,
  complianceDashboards: (state) => state.complianceDashboards,
  msspView: (state) => state.user.mssp_view,
  user: (state) => state.user,
  arcoIntegrations: (state) => state.arcoIntegrations,
  capabilityGroup: (state) => {
    const integrations = state.arcoIntegrations;
    if (!integrations) return null;
    let newArr = [];
    integrations.forEach((d) => {
      if (d.tool_capabilities?.length) {
        d.tool_capabilities.forEach((t) => {
          const capability_group = t.capability?.capability_group;
          newArr.push({
            tool_name: d.name,
            tool_id: d.id,
            capability_group,
            ...d,
          });
        });
      }
    });
    return newArr;
  },
  currentUserId: (state) => state.user?.id || null,
  userRole: (state) => (state.user ? state.user.default_role : null),
  activeAccount: (state) => {
    if (!state.user || !state.user.active_account_id) return null;
    return state.user.active_account_id;
  },
  activeMenu: (state, getters, rootState, rootGetters) => {
    const modules = rootGetters["tenant/modules"] || [];
    const features = rootGetters["tenant/features"] || [];
    if (!state.user || !features?.length)
      return [
        {
          moduleName: "insights",
          name: "Insights",
          routeName: "InsightsHome",
          active: "Insights",
          iconClass: "ai ai-Shield-Check",
        },
        {
          moduleName: "threat",
          name: "Threat",
          routeName: "ThreatHome",
          active: "Threat",
          iconClass: "ai ai-Broadcast",
        },
        {
          name: "Performance",
          routeName: "PerformanceDashboard",
          moduleName: "performance",
          active: "Performance",
          iconClass: "ai ai-Bar-Chart-01-1",
          children: [
            {
              moduleName: "performance",
              featureName: "performance_dashboard",
              name: "Dashboard",
              route: {
                name: "PerformanceDashboard",
                query: { tab: "Dashboard" },
              },
              iconClass: "ai ai-Grid-01",
            },
            {
              moduleName: "performance",
              featureName: "performance_itam",
              name: "ITAM",
              route: {
                name: "PerformanceDashboard",
                query: { tab: "ITAM" },
              },
              iconClass: "ai ai-Computer",
            },
          ],
        },
        {
          name: "Compliance",
          moduleName: "compliance",
          routeName: "ComplianceHome",
          active: "Compliance",
          iconClass: "ai ai-Diploma",
          children: [
            {
              moduleName: "compliance",
              featureName: "compliance_answers",
              name: "Answers",
              route: "ComplianceHome",
              iconClass: "ai ai-Notes-01",
            },
          ],
        },
        {
          name: "Improvement",
          moduleName: "improvement",
          routeName: "ImprovementProjectTable",
          iconClass: "ai ai-Chart-Arrow-UP-01",
          locked: getters.userRole === "sandbox" ? true : false,
        },
      ];
    return [
      {
        moduleName: "mssp_module",
        name: "Home",
        routeName: "AccountsDashboard",
        iconClass: "ai ai-Menu-Home",
      },
      {
        moduleName: "mssp_module",
        name: "Account List",
        iconClass: "ai ai-Briefcase-02",
        featureName: "mssp_module_assessments",
        routeName: "AccountList",
      },
      {
        moduleName: "mssp_module",
        name: "Software Vendors",
        routeName: "ProductHome",
        iconClass: "ai ai-Folder-Accept-01",
      },
      {
        moduleName: "insights",
        name: "Insights",
        routeName: "InsightsHome",
        active: "Insights",
        iconClass: "ai ai-Shield-Check",
      },
      {
        moduleName: "threat",
        name: "Threat",
        routeName: "ThreatHome",
        active: "Threat",
        iconClass: "ai ai-Broadcast",
      },
      {
        name: "Performance",
        routeName: "PerformanceDashboard",
        moduleName: "performance",
        active: "Performance",
        iconClass: "ai ai-Bar-Chart-01-1",
        children: [
          {
            moduleName: "performance",
            featureName: "performance_dashboard",
            name: "Dashboard",
            route: {
              name: "PerformanceDashboard",
              query: { tab: "Dashboard" },
            },
            iconClass: "ai ai-Grid-01",
          },
          {
            moduleName: "performance",
            featureName: "performance_kpi",
            name: "KPI",
            route: {
              name: "PerformanceDashboard",
              query: { tab: "KPIs" },
            },
            iconClass: "ai ai-Target",
          },
          {
            moduleName: "performance",
            featureName: "performance_heatmap",
            name: "Heatmap",
            route: {
              name: "PerformanceDashboard",
              query: { tab: "Heatmap" },
            },
            iconClass: "ai ai-Layers",
          },
          {
            moduleName: "performance",
            featureName: "performance_itam",
            name: "ITAM",
            route: {
              name: "PerformanceDashboard",
              query: { tab: "ITAM" },
            },
            iconClass: "ai ai-Computer",
          },
          {
            moduleName: "performance",
            featureName: "performance_itam_demo",
            name: "ITAM",
            route: {
              name: "PerformanceDashboard",
              query: { tab: "ITAMDemo" },
            },
            iconClass: "ai ai-Computer",
          },
          {
            moduleName: "performance",
            featureName: "performance_reporting",
            name: "Control Risks",
            route: {
              name: "PerformanceDashboard",
              query: { tab: "Control Risks" },
            },
            iconClass: "ai ai-Rank",
          },
          {
            moduleName: "performance",
            featureName: "performance_integrations",
            name: "Integrations",
            route: {
              name: "PerformanceDashboard",
              query: { tab: "Integrations" },
            },
            iconClass: "ai ai-Flash",
          },
        ].filter((f) => features.indexOf(f.featureName) > -1),
      },
      {
        name: "Analytics",
        routeName: "AnalyticsHome",
        moduleName: "analytics",
        active: "Analytics",
        iconClass: "ai ai-Target",
        children: [
          {
            moduleName: "analytics",
            featureName: "analytics_dashboard",
            name: "Dashboard",
            route: {
              name: "AnalyticsDashboard",
              query: { tab: "Dashboard" },
            },
            iconClass: "ai ai-Target",
          },
          {
            moduleName: "analytics",
            featureName: "analytics_builder",
            name: "Analytic Builder",
            route: {
              name: "AnalyticsBuilder",
              query: { tab: "Analytic Builder" },
            },
            iconClass: "ai ai-Flask",
          },
        ],
      },
      {
        name: "Compliance",
        moduleName: "compliance",
        routeName: "ComplianceHome",
        active: "Compliance",
        iconClass: "ai ai-Diploma",
        children: [
          {
            moduleName: "compliance",
            featureName: "compliance_answers",
            name: "Answers",
            route: "ComplianceHome",
            iconClass: "ai ai-Notes-01",
          },
          {
            moduleName: "compliance",
            featureName: "compliance_contributors",
            name: "Contributors",
            route: "ComplianceContributors",
            hidden: getters.userRole === "sandbox" ? true : false,
            iconClass: "ai ai-User-Circle-Add",
          },
          {
            moduleName: "compliance",
            featureName: "compliance_report",
            name: "Report",
            route: "ComplianceReport",
            iconClass: "ai ai-File",
          },
        ].filter((f) => features.indexOf(f.featureName) > -1),
      },
      {
        name: "Improvement",
        moduleName: "improvement",
        routeName: "ImprovementProjectTable",
        iconClass: "ai ai-Chart-Arrow-UP-01",
        locked: getters.userRole === "sandbox" ? true : false,
      },
      {
        name: "Risk",
        moduleName: "risk",
        routeName: "RiskHome",
        active: "Risk",
        iconClass: "ai ai-Matrix",
        children: [
          {
            moduleName: "risk",
            featureName: "risk_measures",
            name: "Risk Measures",
            route: "RiskHome",
            iconClass: "ai ai-Notes-01",
          },
          {
            moduleName: "risk",
            featureName: "risk_simulator",
            name: "Risk Simulator",
            route: "RiskSimulatorHome",
            iconClass: "ai ai-Filter-06",
          },
        ].filter((f) => features.indexOf(f.featureName) > -1),
      },
      {
        name: "Configuration",
        moduleName: "configuration",
        routeName: "ConfigurationUsers",
        iconClass: "ai ai-Setting",
        locked: getters.userRole === "sandbox" ? true : false,
      },
      {
        name: "Administration",
        moduleName: "administration",
        routeName: "AdministrationHome",
        iconClass: "ai ai-Setting",
        locked: getters.userRole === "sandbox" ? true : false,
      },
    ].filter((f) => modules.indexOf(f.moduleName) > -1 || f.global);
  },
  userFrameworkAccess: (state) => state.userFrameworkAccess,
};

const actions = {
  updateJwt({ commit }, content) {
    commit("SET_JWT", content);
  },
  resetAllStores({ commit }) {
    commit("user/RESET_STORE", null, { root: true });
    commit("account/RESET_STORE", null, { root: true });
    commit("compliance_baseline/RESET_STORE", null, { root: true });
    commit("efficiency/RESET_STORE", null, { root: true });
    commit("filters/RESET_STORE", null, { root: true });
    commit("insights/RESET_STORE", null, { root: true });
    commit("notification/RESET_STORE", null, { root: true });
    commit("onboarding/RESET_STORE", null, { root: true });
    commit("project/RESET_STORE", null, { root: true });
    commit("structure/RESET_STORE", null, { root: true });
    commit("tenant/RESET_STORE", null, { root: true });
    commit("ti/RESET_STORE", null, { root: true });
    commit("ui/RESET_STORE", null, { root: true });
    commit("threat/RESET_STORE", null, { root: true });
    commit("findings/RESET_STORE", null, { root: true });
  },
  async updateFrameworkState({ rootGetters, commit }, payload) {
    try {
      const currentState = rootGetters["user/userFrameworkState"] || {};
      const { data } = await query({
        query: gql_update_user_framework_state,
        variables: {
          id: state.user.id,
          state_account_framework: {
            ...currentState,
            [payload.account_id]: payload.framework_id,
          },
        },
      });
      const curUsr = data.update_user_by_pk;
      commit("UPDATE_USER", { user: curUsr });
    } catch (error) {
      console.error(error);
    }
  },
  setHomeComponents({ commit }, payload) {
    commit("SET_HOME_COMPONENTS", payload);
  },
  async getArcoIntegrations({ commit }) {
    const {
      data: { tool },
    } = await query({
      query: gqlGetArcoIntegrations,
    });
    if (tool) {
      commit("SET_ARCO_INTEGRATIONS", tool);
    }
  },
  async updateUser(
    { state, commit, dispatch },
    { userId = null, userUpdateObj, rehydrate = true }
  ) {
    try {
      const { data } = await query({
        query: update_user_by_pk,
        variables: {
          id: userId || state.user.id,
          object: userUpdateObj,
        },
      });
      if (data?.update_user_by_pk) {
        const curUsr = data.update_user_by_pk;
        const userBrand = curUsr.active_brand || curUsr.default_brand || null;
        commit("UPDATE_USER", { user: curUsr });
        if (!rehydrate) return;
        if (userBrand) dispatch("rehydrateBranding", userBrand);
        dispatch("rehydrateUser", {
          usr: curUsr,
          isInitial: false,
          changes: userUpdateObj,
        });
        dispatch("performance_dashboard/changeDataMode", "one", { root: true });
      }
    } catch (error) {
      console.error(error);
    }
  },
  async inviteNewUser({}, userObject) {
    try {
      const { data } = await axios.post(
        `${import.meta.env.VITE_APP_AUTH_URL}/new_user`,
        userObject,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
          },
        }
      );

      if (data) {
        ElNotification({
          title: "User has been successfully invited.",
          message: "They may need to check their spam folder",
          type: "success",
        });
      }
    } catch (error) {
      ElNotification({
        title: "Problem inviting user.",
        message: error.message,
        type: "error",
      });
    }
  },
  async rehydrateBranding({ dispatch }, userBrand) {
    try {
      if (!userBrand) return;
      if (import.meta.env.VITE_FIXED_BRANDING === "true") return;

      localStorage.setItem("tenant_id", userBrand.tenant_id);
      dispatch("ui/changeTheme", userBrand, { root: true });
      if (userBrand.VUE_APP_TITLE) {
        dispatch("ui/changeTitle", userBrand.VUE_APP_TITLE, { root: true });
      } else {
        dispatch("ui/changeTitle", "Arco Cyber", { root: true });
      }
    } catch (error) {
      console.error(error);
    }
  },
  async rehydrateUser(
    { dispatch, commit },
    { usr, isInitial = false, changes }
  ) {
    commit("SET_LOADING", true);
    try {
      const currentFramework = usr?.current_framework_id;
      const currentAccount =
        usr?.active_account_id ||
        usr?.active_tenant_id ||
        usr?.tenant_id ||
        null;

      if (currentAccount) {
        if (isInitial) {
          dispatch(
            "account/getAccountDetail",
            { account_id: currentAccount },
            { root: true }
          );
          dispatch("performance_dashboard/fetchGlobalMetrics", null, {
            root: true,
          });
          dispatch("performance_dashboard/fetchAssetCounts", null, {
            root: true,
          });
          dispatch("efficiency/getSection", null, { root: true });
          dispatch("account/getAccountAll", null, { root: true });
          dispatch("tenant/getTenantData", null, { root: true });
          dispatch("insights/getNoOrSome", currentAccount, { root: true });
          dispatch("efficiency/getQuestionsBySection", currentAccount, {
            root: true,
          });
          dispatch("ti/getThreatProfile", currentAccount, { root: true });
          dispatch("ti/getControlRisksVariety", currentAccount, {
            root: true,
          });
          dispatch("ti/getControlRisksVolume", currentAccount, {
            root: true,
          });
          dispatch("ti/getControlRisksThreatProfile", currentAccount, {
            root: true,
          });
          dispatch(
            "findings/getCurrentActuals",
            { account_id: currentAccount, framework_id: currentFramework },
            { root: true }
          );
          commit("SET_LOADING", false);
        } else {
          if (changes.active_account_id) {
            dispatch(
              "account/getAccountDetail",
              { account_id: currentAccount },
              { root: true }
            );
            dispatch("performance_dashboard/fetchGlobalMetrics", null, {
              root: true,
            });
            dispatch("performance_dashboard/fetchAssetCounts", null, {
              root: true,
            });
            dispatch("insights/getNoOrSome", currentAccount, { root: true });
            dispatch("efficiency/getQuestionsBySection", currentAccount, {
              root: true,
            });
            dispatch("ti/getControlRisksVariety", currentAccount, {
              root: true,
            });
            dispatch("ti/getControlRisksVolume", currentAccount, {
              root: true,
            });
            dispatch("ti/getThreatProfile", currentAccount, { root: true });
            dispatch("ti/getControlRisksThreatProfile", currentAccount, {
              root: true,
            });
            dispatch(
              "findings/getCurrentActuals",
              { account_id: currentAccount, framework_id: currentFramework },
              { root: true }
            );
            commit("SET_LOADING", false);
          }
        }
      }
      dispatch("threat/getIndustryCountsOfTTPs", null, { root: true });
      dispatch("findings/getSumOfAnswers", null, { root: true });
    } catch (error) {
      console.error(error);
    }
  },
  async getCurrentUser({ commit, dispatch }) {
    try {
      const { data } = await query({
        query: getUser,
      });
      if (data.user?.[0]) {
        const curUsr = data.user[0];
        const userBrand = curUsr.active_brand || curUsr.default_brand || null;
        commit("SET_CURRENT_USER", curUsr);
        if (userBrand) dispatch("rehydrateBranding", userBrand);
        dispatch("rehydrateUser", { usr: curUsr, isInitial: true });
        const tenantData = await query({
          query: getTenant,
        });

        const tenant = tenantData.data.tenant_for_user[0];
        commit("tenant/SET_TENANT_DATA", tenant, { root: true });
        commit("tenant/SET_TENANT_LOADED", null, { root: true });

        const subscriptionPayload = {
          tenant_id: tenant.id,
          subscription_id: tenant.subscription_id,
          role: curUsr.default_role,
        };

        if (tenant.subscription.name === "custom") {
          await dispatch(
            "tenant/getCustomSubscriptionData",
            subscriptionPayload,
            { root: true }
          );
        } else {
          await dispatch("tenant/getSubscriptionData", subscriptionPayload, {
            root: true,
          });
        }

        if (curUsr.default_role === "mssp_rw") {
          await dispatch("fetchUserFrameworkAccess", curUsr.id);
        }

        return curUsr;
      }
    } catch (error) {
      console.error(error);
    }
  },
  async userComplianceDashboards(
    { rootGetters, commit },
    { user_id, framework_id }
  ) {
    try {
      const { data } = await query({
        query: getComplianceDashboards,
        variables: {
          user_id: user_id,
          framework: framework_id,
        },
      });

      let dashboards = data?.compliance_widgets ? data.compliance_widgets : [];
      const requiredModules = ["trends", "findings"];
      for (let module of requiredModules) {
        if (!dashboards.some((dashboard) => dashboard.module === module)) {
          const defaultData = await query({
            query: getDefaultComplianceDashboards,
            variables: {
              framework: framework_id,
              module: module,
            },
          });
          if (defaultData?.data?.compliance_widgets.length) {
            const isBasic = rootGetters["tenant/isBasic"] || null;
            if (module == "findings" && isBasic) {
              let filteredDefault = [...defaultData.data.compliance_widgets];

              filteredDefault.forEach((item) => {
                item.layout_obj = item.layout_obj.filter(
                  (obj) => obj.title !== "Non-Compliant Controls"
                );
              });

              filteredDefault[0].layout_obj.forEach((item) => {
                if (item.title === "Your Average Maturity") {
                  item.w = 5;
                  item.x = 0;
                }
                if (item.component === "ComplianceFindingsSummaryCards") {
                  item.w = 7;
                  item.x = 5;
                }
              });
              dashboards.push(filteredDefault[0]);
            } else {
              dashboards.push(...defaultData.data.compliance_widgets);
            }
          } else {
            const defaultDataC = await query({
              query: getDefaultComplianceDashboards,
              variables: {
                framework: 30,
                module: module,
              },
            });

            if (defaultDataC?.data?.compliance_widgets) {
              dashboards.push(...defaultDataC.data.compliance_widgets);
            }
          }
        }
      }

      commit("SET_COMPLIANCE_DASHBOARDS", dashboards);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchUserFrameworkAccess({ commit }) {
    try {
      const { data } = await query({
        query: getUserFrameworkAccess,
      });

      if (data && data.user_framework_access) {
        const frameworkIds = data.user_framework_access.map(
          (item) => item.framework_id
        );
        commit("SET_USER_FRAMEWORK_ACCESS", frameworkIds);
      }
    } catch (error) {
      console.error("Error fetching user framework access:", error);
    }
  },
};
// mutations
const mutations = {
  RESET_STORE(state) {
    Object.assign(state, defaultStoreState());
  },
  SET_LOADING(state, value) {
    state.loading = value;
  },
  SET_HOME_COMPONENTS(state, content) {
    state.homeComponents.account = content.account;
    state.homeComponents.mssp = content.mssp;
  },
  SET_CURRENT_USER(state, content) {
    state.user = content;
  },
  SET_JWT(state, content) {
    state.jwt = content;
  },
  SET_IMPERSONATION(state, content) {
    state.isImpersonating = content;
  },
  SET_ARCO_INTEGRATIONS(state, content) {
    state.arcoIntegrations = content;
  },
  SET_COMPLIANCE_DASHBOARDS(state, content) {
    state.complianceDashboards = content;
  },
  UPDATE_USER(state, { user }) {
    Object.assign(state.user, user);
  },
  SET_USER_FRAMEWORK_ACCESS(state, frameworkIds) {
    state.userFrameworkAccess = frameworkIds;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
